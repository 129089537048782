<template>
    <div class="p-5">

        <div v-for="route in routes" class="inline" style="width: 400px">
          <b>{{ route.name }}</b> <br/> <small>{{ route.path }}</small>
        </div>

    </div>
</template>
<style>

</style>
<script>

    export default {
        components: {},
        data() {
            return {
              routes: []
            }
        }
      ,
      mounted() {
        this.routes = this.$router.getRoutes();
      }
    }
</script>
